import { LocationsAndAggregations, SiteProps } from '@zupr/types/next'
import { locationsSchema } from '@zupr/utils/schema'
import Frontoffice from '@zupr/web/src/js/frontoffice/components/base'
import Providers from '@zupr/web/src/js/frontoffice/providers'
import Locations from '@zupr/web/src/js/frontoffice/screens/locations/list'
import { GetServerSideProps } from 'next'

import Schema from '../../components/schema'
import { getLocationsAndAggregations } from '../../helpers/locations'
import { getProps } from '../../helpers/props'

interface PageProps extends SiteProps {
    locationsAndAggregations: LocationsAndAggregations
    schema: Awaited<ReturnType<typeof locationsSchema>>
}
export const getServerSideProps: GetServerSideProps<PageProps> = async ({ req, params, query }) => {

    const siteProps = await getProps({ req, params, query })

    const locationsAndAggregations = await getLocationsAndAggregations({
        query: siteProps.query,
        params: {
            shopping_areas: siteProps.domain.slug,
            classification: params.classification as string,
        },
    }, req)

    const schema = await locationsSchema({
        locationsAndAggregations,
        req,
    })

    return {
        props: {
            ...siteProps,
            locationsAndAggregations,
            schema
        },
    }
}

const Index = ({
    locationsAndAggregations,
    schema,
    ...siteProps
}: PageProps) => (
    <Providers {...siteProps}>
        <Frontoffice>
            <Locations {...locationsAndAggregations} />
        </Frontoffice>
        <Schema schema={schema} id={siteProps.hash} />
    </Providers>
)

export default Index
